import { sitedata } from '@data';
/* eslint-disable max-len */ import { getSEO, PageSEO } from '@seo';
import { Link } from 'gatsby';
import React from 'react';

interface Props {
  location: {
    pathname: string;
  };
}

const { siteName, siteUrl } = sitedata;

const TermsOfUse = ({ location }: Props) => {
  const { pathname } = location;
  const seo = getSEO(pathname);

  return (
    <>
      <PageSEO
        description={seo.description}
        featureImage="og-terms-of-use.jpg"
        pathname={pathname}
        title={seo.title}
      />
      <div className="max-w-2xl px-4 pt-16 pb-20 mx-auto text-base text-black lg:max-w-3xl xs:px-6 lg:pt-24 lg:pb-28 md:px-8 dark:text-white">
        <div className="mb-6">
          <h1 className="mb-4 text-5xl font-bold">Terms of Use</h1>
          <p className="text-sm dark:text-gray-400">
            Last Modified: 12th September 2021
          </p>
        </div>

        <div className="leading-8">
          <p className="mb-4">
            These terms and conditions outline the rules and regulations for the
            use of {siteName} Website, located at{' '}
            <Link className="inline-link" to="/">
              {siteUrl}
            </Link>
            .
          </p>
          <p className="mb-8">
            By accessing this Website we assume you accept these terms and
            conditions. Do not continue to use{' '}
            <span className="font-bold dark:text-red-200">{siteUrl} </span>
            if you do not agree to take all of the terms and conditions stated
            on this page.
          </p>

          <h2 className="mb-3 text-3xl">Terminology</h2>
          <p className="mb-4">
            The following terminology applies to these Terms and Conditions,
            Privacy Policy and all Agreements:
          </p>
          <ul className="mb-8 ml-5 space-y-4 list-disc">
            <li>
              <em>&quot;Visitor&quot;</em>, <em>&quot;You&quot;</em> and{' '}
              <em>&quot;your&quot;</em> refers to you, the person log on this
              Website compliant to this website’s <em>Terms and Conditions</em>.
            </li>
            <li>
              <li>
                <em>&quot;The Company&quot;</em>, <em>&quot;Ourselves&quot;</em>
                , <em>&quot;We&quot;</em>, <em>&quot;Our&quot;</em> and{' '}
                <em>&quot;Us&quot;</em>, refers refers to our Company.{' '}
                <em>&quot;Party&quot;</em>, <em>&quot;Parties&quot;</em>, or{' '}
                <em>&quot;Us&quot;</em>, refers to both the visitor and
                ourselves.
              </li>
            </li>
          </ul>
          <p className="mb-8">
            Any use of the above terminology or other words in the singular,
            plural, capitalization and/or he/she or they, are taken as
            interchangeable and therefore as referring to same.
          </p>

          <p className="mb-8">
            By accessing this Website, you agreed to use cookies in agreement
            with the Website&apos;s{' '}
            <Link className="inline-link" to="/privacy-policy">
              Privacy Policy
            </Link>
            .
          </p>

          <h2 className="mb-3 text-3xl">License</h2>
          <p className="mb-8">
            Unless otherwise stated,{' '}
            <span className="font-bold dark:text-red-200">{siteName}</span>{' '}
            and/or its licensors own the intellectual property rights for all
            material on{' '}
            <span className="font-bold dark:text-red-200">{siteUrl}</span>. All
            intellectual property rights are reserved. You may access this from{' '}
            <span className="font-bold dark:text-red-200">{siteUrl}</span> for
            your own personal use subjected to restrictions set in these terms
            and conditions.
          </p>

          <em>You must not:</em>
          <ul className="mb-8 ml-5 space-y-4 list-disc">
            <li>
              Republish material from{' '}
              <span className="font-bold dark:text-red-200">{siteUrl}</span>.
            </li>
            <li>
              Sell, rent or sub-license material from{' '}
              <span className="font-bold dark:text-red-200">{siteUrl}</span>.
            </li>
            <li>
              Reproduce, duplicate or copy material from{' '}
              <span className="font-bold dark:text-red-200">{siteUrl}</span>.
            </li>
            <li>
              Redistribute content from{' '}
              <span className="font-bold dark:text-red-200">{siteUrl}</span>.
            </li>
          </ul>

          <h2 className="mb-3 text-3xl">Hyperlinking to our Content</h2>
          <p className="mb-4">
            The following organizations may link to our Website without prior
            written approval:
          </p>

          <ul className="mb-8 ml-5 space-y-4 list-disc">
            <li>Government agencies.</li>
            <li>Search engines.</li>
            <li>News organizations.</li>
            <li>
              Online directory distributors may link to our Website in the same
              manner as they hyperlink to the websites of other listed
              businesses and System wide Accredited Businesses except soliciting
              non-profit organizations, charity shopping malls, and charity
              fundraising groups which may not hyperlink to our Website. These
              organizations may link to our home page, to publications or to
              other Website information so long as the link:
              <ol className="pt-4 mb-8 ml-5 space-y-1 list-disc">
                <li>Is not in any way deceptive</li>
                <li>
                  Does not falsely imply sponsorship, endorsement or approval of
                  the linking party and its products and/or services.
                </li>
                <li>Fits within the context of the linking party’s site.</li>
              </ol>
            </li>
          </ul>

          <p className="mb-8">
            No use of {siteName}&apos;s logo or other artwork will be allowed
            for linking absent a trademark license agreement.
          </p>

          <h2 className="mb-3 text-3xl">iFrames</h2>
          <p className="mb-8">
            Without prior approval and written permission, you may not create
            frames around our Webpages.
          </p>

          <h2 className="mb-3 text-3xl">Reservation of Rights</h2>
          <p className="mb-4">
            We reserve the right to request that you remove all links or any
            particular link to our Website. You approve to immediately remove
            all links to our Website upon request.
          </p>
          <p className="mb-4">
            We also reserve the right to amen these terms and conditions and
            it’s linking policy at any time.
          </p>
          <p className="mb-8">
            By continuously linking to our Website, you agree to be bound to and
            follow these linking terms and conditions.
          </p>

          <h2 className="mb-3 text-3xl">Removal of links from our Website</h2>
          <p className="mb-4">
            If you find any link on our Website that is offensive for any
            reason, you are free to contact and inform us any moment.
          </p>
          <p className="mb-4">
            We will consider requests to remove links but we are not obligated
            to or so or to respond to you directly.
          </p>
          <p className="mb-8">
            We do not ensure that the information on this Website is correct, we
            do not warrant its completeness or accuracy; nor do we promise to
            ensure that the Website remains available or that the material on
            the Website is kept up to date.
          </p>

          <h2 className="mb-3 text-3xl">Disclaimer</h2>
          <p className="mb-4">Nothing in this disclaimer will:</p>
          <ul className="mb-8 ml-5 space-y-4 list-disc">
            <li>
              Limit or exclude our or your liability for death or personal
              injury.
            </li>
            <li>
              Limit or exclude our or your liability for fraud or fraudulent
              misrepresentation.
            </li>
            <li>
              Limit any of our or your liabilities in any way that is not
              permitted under applicable law.
            </li>
            <li>
              Exclude any of our or your liabilities that may not be excluded
              under applicable law.
            </li>
            <li>
              The limitations and prohibitions of liability set in this Section
              and elsewhere in this disclaimer:
              <ol className="pt-4 mb-8 ml-5 space-y-1 list-disc">
                <li>Are subject to the preceding paragraph.</li>
                <li>
                  Govern all liabilities arising under the disclaimer, including
                  liabilities arising in contract, in tort and for breach of
                  statutory duty. As long as the website and the information and
                  services on the website are provided free of charge, we will
                  not be liable for any loss or damage of any nature.
                </li>
              </ol>
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default TermsOfUse;
